import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

export const query = graphql`
  fragment Meta on MarkdownRemark {
    frontmatter {
      meta {
        title
        description
        noindex
        canonicalLink
        keywords
      }
    }
  }
`

export default class Meta extends Component {
  render() {
    const {
      title,
      url,
      description,
      absoluteImageUrl = '',
      twitterSiteAccount,
      twitterCreatorAccount,
      noindex,
      canonicalLink,
      keywords,
      siteTitle,
      siteDescription,
      meta,
      googleTrackingId,
      sClick
      // overwrite { title, description } if in fields or fields.meta
    } = this.props

    return (
      <Helmet>
        <html lang="cs" />
        <link rel="alternate" href="https://danovekontroly.cz/" hreflang="cs" />

        <meta name="author" content="Ondrej Starha"></meta>
        {title && <title>{title}</title>}
        {title && <meta property="og:title" content={title} />}
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {url && <meta property="og:type" content="website" />}
        {url && <meta property="og:url" content={url} />}
        {twitterSiteAccount && (
          <meta name="twitter:site" content={twitterSiteAccount} />
        )}
        {twitterCreatorAccount && (
          <meta name="twitter:creator" content={twitterCreatorAccount} />
        )}
        {noindex && <meta name="robots" content="noindex" />}
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={siteTitle} />
        <meta name="twitter:description" content={siteDescription} />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:image" content={absoluteImageUrl} />
        <meta property="og:image:secure_url" content={absoluteImageUrl} />
        <meta property="og:image" content={absoluteImageUrl} />
        <meta name="twitter:card" content={absoluteImageUrl} />
        <meta name="facebook:description" content={siteDescription} />
        <meta name="facebook:title" content={siteTitle} />
        <meta name="facebook:image" content={absoluteImageUrl} />
        <meta property="og:image:secure_url" content={absoluteImageUrl} />
        <meta property="og:image" content={absoluteImageUrl} />
        <meta name="facebook:card" content={absoluteImageUrl} />
        <meta name="robots" content="index, follow" />

        {googleTrackingId && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleTrackingId}`}
          ></script>
        )}

        {googleTrackingId && (
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${googleTrackingId}');
            `}
          </script>
        )}

        <script type="application/ld+json">
          {`
            "@context": "http://schema.org",
            "@type": "Organization",
            "legalName": "EK Partners s.r.o.",
            "name": "EKP",          
            "description":"Jsme poradenskou společností a klientům pomáháme zejména v oblasti daní a účetnictví. Naší snahou je vždy patřit mezi špičku na trhu a poskytujeme jak vedení účetnictví, zpracování mezd nebo přípravu daňových přiznání, tak specializované služby, jako účetní reporting a controlling, poradenství při přeměnách společnosti, přesunech majetku, nebo vypracování znaleckých posudků.",
            "url": "https://danovekontroly.cz",
            "logo":"https://danovekontroly.cz/imgages/logo.png",
            "contactPoint" : [{
            "@type" : "ContactPoint",
            "telephone" : "+420 273 139 330",
            "contactType" : "customer service"
            }]
            `}
        </script>

        <script type="text/javascript">
          {`window.smartlook||(function(d) 
          { var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0]; 
          var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript'; 
          c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c); })(document); 
          smartlook('init', 'd46ee051c86803a497cad0afa569ca33f6fb31ff')`}
        </script>
        <script type="text/javascript">{`${sClick}`}</script>
        {
          <script
            type="text/javascript"
            src="https://www.seznam.cz/rs/static/rc.js"
            async
          />
        }
      </Helmet>
    )
  }
}
