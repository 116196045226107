import React, { useState } from 'react'
import './styles.css'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Menu, X } from 'react-feather'

const NavigationBarPreview = ({ logo }) => {
  const [active, setActive] = useState(false)
  return (
    <header>
      <div className="container-Nav">
        <nav className={active ? 'active' : ''}>
          <div onClick={() => setActive(!active)} className="menu-icons">
            <X className="ion-md-close" />
            <Menu className="ion-md-menu" />
          </div>
          <Link to="/">
            <img className="logo" src={logo.fluid.src} alt="logo" />
          </Link>
          <ul className="nav-list">
            <li className="move-left">
              {/* <div className="btn">
                <a
                  className="black-button"
                  href="mailto:&#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;"
                >
                  &#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;
                </a>
              </div> */}

              {/* <div className="btn">
                <a className="black-button" href="tel:+420273139330">
                  +420 273 139 330
                </a>
              </div> */}
            </li>
            <li>
              <Link className="uppercase" to="/fu-hleda-data/">
                FÚ hledá data
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/fu-hleda-data/vysvetleni/">Vysvětlení</Link>
                </li>
                <li>
                  <Link to="/fu-hleda-data/mistni-setreni/">
                    Místní šetření
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="uppercase" to="/kontroly-z-pohledu-fu/">
                Kontrola z pohledu FU
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/kontroly-z-pohledu-fu/postup-k-odstraneni-pochybnosti/">
                    Postup k odstranění pochybností
                  </Link>
                </li>
                <li>
                  <Link to="/kontroly-z-pohledu-fu/danova-kontrola/">
                    Daňová kontrola
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="uppercase" to="/poruseni-rozpoctove-kazne/">
                Porušení rozpočtové kázně
              </Link>
            </li>
            <li>
              <Link className="uppercase" to="/pro-advokaty/">
                Pro advokáty
              </Link>
            </li>
            <li>
              <Link className="uppercase" to="/zajistovaci-prikazy">
                Zajišťovací příkazy
              </Link>
            </li>
            <li>
              <Link className="uppercase" to="/zaloby-kasace">
                Žaloby
              </Link>
            </li>
            <li>
              <Link className="uppercase" to="/aktuality/">
                Aktuality
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/aktuality/aktuality/">Aktuality</Link>
                </li>
                <li>
                  <Link to="/aktuality/judikatury/">Daňová judikatura</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="uppercase" to="/kontakt">
                Kontakt
              </Link>
            </li>

            {/* <li>
              <div className="move-top">
                <div className="btn-small">
                  <a
                    className="black-button"
                    href="mailto:&#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;"
                  >
                    &#101;&#107;&#112;&#64;&#101;&#107;&#112;&#46;&#99;&#122;
                  </a>
                </div>
              </div>
              <div className="move-top">
                <div className="btn-small">
                  <a className="black-button" href="tel:+420273139330">
                    +420 273 139 330
                  </a>
                </div>
              </div>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  )
}

const NavigationBar = ({ location }) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "hp-logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
    }
  `)
  return (
    <NavigationBarPreview logo={logo.childImageSharp} location={location} />
  )
}

export default NavigationBar
